@font-face {
  font-family: 'ObjectiveMK1-Xbd';
  src: url('../fonts/ObjektivMk1WXBd.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'ObjectiveMK1-Rg';
  src: url('../fonts/ObjektivMk1WRg.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'ObjectiveMK1-Md';
  src: url('../fonts/ObjektivMk1WMd.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'ObjectiveMK2-Md';
  src: url('../fonts/ObjektivMk2WMd.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'ObjectiveMK1-Th';
  src: url('../fonts/ObjektivMk1WTh.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'ObjectiveMK2-Th';
  src: url('../fonts/ObjektivMk2WTh.woff2') format('woff2');
  font-display: swap;
}
